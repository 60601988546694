<template>
  <b-container class="my-2 my-sm-5" fluid>
    <b-row align-v="center" class="mb-3">
      <b-col>
        <h1>
          {{ $t("missions.pagename") }}
        </h1>
      </b-col>
      <b-col cols="auto">
        <b-button
          style="position: relative"
          variant="primary"
          to="/challenge/add"
        >
          {{ $t("missions.add_mission") }}
          <span
            v-if="challenge_notifications"
            class="challenge_notification badge badge-pill badge-highlight"
            style="font-size: 0.8rem;"
          >
            {{ challenge_notifications }}
          </span>
        </b-button>
      </b-col>
    </b-row>

    <b-overlay :show="loading">
      <b-tabs
        content-class="mt-2"
        class="tabs-clear"
        nav-class="flex-row w-100"
        :justified="['xs', 'sm', 'md'].includes($store.state.breakpoint)"
        lazy
      >
        <!-- ACTIVE MISSIONS -->
        <b-tab :title="$t('missions.active')">
          <b-row cols="1" cols-sm="2" cols-lg="3" cols-xl="4">
            <b-col
              v-for="mission in activeMissions"
              :key="mission.id"
              class="mb-4"
            >
              <MissionCard
                :src="`${mission.file_path}${mission.file_name}`"
                :title="mission.name"
                :to="
                  `${mission.challenge_id}/version/${mission.user_campaign_version_id}/instance/${mission.id}`
                "
              >
                <template>
                  <small class="text-terciary mb-2">{{
                    formatMissionDate(mission)
                  }}</small>
                  <b-row class="text-terciary">
                    <b-col cols="4" class="text-nowrap">
                      <small>{{ $t("points") }}: </small>
                      <div>
                        <small> {{ mission.total_points }} p </small>
                      </div>
                    </b-col>

                    <b-col>
                      <small class="text-nowrap">
                        {{ $t("missions.weekly_goal") }}:
                      </small>
                      <div class="text-secondary text-nowrap">
                        <b-icon-check-circle-fill
                          class="mr-1"
                          v-for="i in mission.no_of_successful_completes"
                          :key="`-${i}`"
                          font-scale="1.25"
                        />
                        <b-icon-circle
                          class="mr-1"
                          v-for="i in mission.no_of_successful_completes_goal -
                            mission.no_of_successful_completes"
                          :key="i"
                          font-scale="1.25"
                        />
                      </div>
                    </b-col>
                  </b-row>
                </template>
              </MissionCard>
            </b-col>

            <b-col
              v-if="!activeMissions.length"
              class="text-center mx-auto my-2"
            >
              {{ $t("missions.no_active") }}
            </b-col>
          </b-row>
        </b-tab>

        <!-- ENDEND MISSIONS -->
        <b-tab :title="$t('missions.ended')">
          <b-row cols="1" cols-sm="2" cols-lg="3" cols-xl="4">
            <b-col
              v-for="mission in endedMissions"
              :key="mission.id"
              class="mb-4"
            >
              <MissionCard
                :src="`${mission.file_path}${mission.file_name}`"
                :title="mission.name"
                :to="
                  `${mission.challenge_id}/version/${mission.user_campaign_version_id}/instance/${mission.id}`
                "
                class="mb-4"
                grayscale
              >
                <template #overlay>
                  <b-icon-check-circle-fill
                    v-if="mission.complete_dt"
                    class="text-secondary position-absolute rounded-circle bg-white svg-bg-fix"
                    style="width: auto; height: 50%; top: 50%; right: 1rem; transform: translateY(-50%);"
                  />
                </template>

                <template>
                  <b-row>
                    <b-col>
                      <div class="text-nowrap">{{ $t("points") }}:</div>
                      <div class="text-terciary">{{ mission.total_points }}</div>
                    </b-col>
                    <b-col>
                      <div class="text-nowrap">{{ $t("date") }}:</div>
                      <div class="text-terciary">
                        {{ mission.last_reporting_dt }}
                      </div>
                    </b-col>
                  </b-row>
                </template>
              </MissionCard>
            </b-col>

            <b-col v-if="!endedMissions.length" class="text-center mx-auto">
              {{ $t("missions.no_ended") }}
            </b-col>
          </b-row>
        </b-tab>
      </b-tabs>
    </b-overlay>
  </b-container>
</template>

<script>
import axios from "axios"
import MissionCard from "@/components/MissionCard.vue"
import { BIconCircle, BIconCheckCircleFill } from "bootstrap-vue"

export default {
  components: {
    MissionCard,
    BIconCircle,
    BIconCheckCircleFill,
  },
  data() {
    return {
      /** @type {Array<any>} */
      missions: [],
      loading: false,
      error: "",
      showEnded: false,
      challenge_notifications: 0,
    }
  },
  computed: {
    /** @return {Array<any>} */
    activeMissions() {
      // This is 00:00 the next day, compare using less than (not equals)
      const last_reporting_dt_iso = last_reporting_dt => {
        let date = new Date(`${last_reporting_dt} ${new Date().getFullYear()}`)
        date.setHours(24) // increment to next day
        return date
      }
      return this.missions.filter(
        mission =>
          mission.is_completed != "Y" &&
          new Date() < last_reporting_dt_iso(mission.last_reporting_dt)
      )
    },
    /** @return {Array<any>} */
    endedMissions() {
      return this.missions.filter(
        mission => !this.activeMissions.includes(mission)
      )
    },
  },

  async created() {
    this.getMissions()
    this.getNotifications()
  },

  methods: {
    async getMissions() {
      this.loading = true
      this.error = ""

      try {
        const response = await axios.get("/mychallenges")
        this.missions = response.data?.data ?? []
      } catch ({ name, message, response }) {
        this.$store.dispatch("addToastMessage", {
          type: "danger",
          text: response.data.error_message ?? `${name}: ${message}`,
        })
      } finally {
        this.loading = false
      }
    },
    async getNotifications() {
      try {
        const response = await axios.get("/challengenotifications")
        this.challenge_notifications = response.data?.new_challenge_proposals
      } catch ({ name, message, response }) {
        this.$store.dispatch("addToastMessage", {
          type: "danger",
          text: response.data.error_message ?? `${name}: ${message}`,
        })
      }
    },
    formatMissionDate(mission) {
      let days_left =
        Math.floor(
          (new Date(mission.valid_to_dt).getTime() - new Date().getTime()) /
            (1000 * 60 * 60 * 24)
        ) + 1
      return days_left < 0
        ? this.$t("missions.has_expired")
        : `${days_left} ${this.$tc(
            "days_left",
            Math.max(0, days_left)
          )}`.toLocaleLowerCase()
    },
  },
}
</script>

<style scoped>
.challenge_notification {
  position: absolute;
  top: 0;
  left: 100%;
  transform: translate(-50%, -50%);
}
</style>
